export default class OAuthOneTimePassword {
    otp: string;

    constructor (
      otp: string
    ) {
      this.otp = otp;
    }

    static fromJson (data: Record<string, unknown>): OAuthOneTimePassword {
      return new OAuthOneTimePassword(
          data.otp as string
      );
    }
}
