<script setup lang="ts">
</script>

<template>
  <div class="not-found-container">
      <img
        src="/mask-group.jpg"
        class="image-container"
      >
      <div class="main-text">
        <h1>
          DEPLOY A SOULBOUND CLAIM PAGE
        </h1>
        <div class="not-found-header">
          Create a soulbound Open or Closed Edition claim page. Tokens cannot be transferred to another address, only burned.

        </div>
        <div class="not-found-header">
          Deploy a souldrop.page from Manifold Studio
        </div>

        <div class="not-found-header">
          For additional questions or support, please contact <a href="https://twitter.com/sweetdropnft" target="_blank" rel="noreferrer">@sweetdropnft</a>.
        </div>
      </div>

      <a
        target="_blank"
        href="https://studio.manifold.xyz/apps"
        class="bg-black text-white p-2 mt-4 cursor-pointer"
      >
        MANIFOLD STUDIO
      </a>
  </div>
</template>

<style lang="scss" scoped>

.not-found-container {
  max-width: 805px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: 'Inconsolata', 'Inter', sans-serif !important;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;

  div {
    font-size: 24px;
    font-family: 'Inconsolata', 'Inter', sans-serif !important;
  }

  h1 {
    font-size: 32px;
    font-weight: 800;
    margin-top: 2.5rem;
    font-family: 'Inter', sans-serif !important;
  }

  .example {
    margin-bottom: 2rem;
    font-size: 24px;
    font-family: 'Inconsolata', 'Inter', sans-serif !important;

    a {
      background-color: inherit;
      color: black;
    }
  }

  .not-found-header {
    font-size: 18px;
    margin-top: 1.5rem;
    font-family: 'Inconsolata', 'Inter', sans-serif !important;

    a {
      border-radius: 0;
      color: black;
      background-color: white;
      margin-top: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Inconsolata', 'Inter', sans-serif !important;
      text-decoration: underline;
    }
  }

  a {
    background-color: black;
    color: white;
    padding: 1rem 1.5rem;
    cursor: pointer;
    margin-top: 2.5rem;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Inter', sans-serif !important;
    border-radius: 5px;
  }

  .image-container {
    height: 227px;
    width: 227px;
    border-radius: 40px;
    font-family: 'Inconsolata', 'saira', sans-serif !important;
  }
}

</style>
