
export type Creator = {
  id: number;
  address: string;
  name: string;
  image: string;
};

export type Email = {
  type: 'email';
  metadata: Record<any, never>;
};

export enum ClaimType {
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
}

export type ClaimLimitType = 'whitelist' | 'limited' | 'open' | 'unknown';

export class ClaimConfig {
  id: string;
  userId: string;
  name: string;
  image: string;
  description: string;
  slug: string;

  audienceId: number;
  extensionAddress: string;
  creatorContractAddress: string;
  claimIndex: number;
  claimType: ClaimType;
  arweaveURL: string;
  isPayable: boolean;
  animation: string;
  fallbackProvider?: string;

  creator: Creator;
  startDate?: Date;
  endDate?: Date;

  constructor (
    id: string,
    userId: string,
    creator: Creator,
    name: string,
    image: string,
    description: string,
    slug: string,

    audienceId: number,
    extensionAddress: string,
    creatorContractAddress: string,
    claimIndex: number,
    claimType: ClaimType,
    arweaveURL: string,
    isPayable: boolean,
    animation: string,

    fallbackProvider?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    this.id = id;
    this.name = name;
    this.creator = creator;
    this.userId = userId;
    this.image = image;
    this.description = description;
    this.slug = slug;

    this.audienceId = audienceId;
    this.extensionAddress = extensionAddress;
    this.creatorContractAddress = creatorContractAddress;
    this.claimIndex = claimIndex;
    this.claimType = claimType;
    this.arweaveURL = arweaveURL;
    this.isPayable = isPayable;
    this.animation = animation;
    this.fallbackProvider = fallbackProvider;

    this.startDate = startDate;
    this.endDate = endDate;
  }

  static fromJson (data: Record<string, unknown>): ClaimConfig {
    const publicData = data.publicData as Record<string, unknown>;
    return new ClaimConfig(
        data.id as string,
        data.userId as string,
        data.creator as Creator,
        publicData.name as string,
        publicData.image as string,
        publicData.description as string,
        data.slug as string,

        publicData.audienceId as number,
        publicData.extensionAddress as string,
        publicData.creatorContractAddress as string,
        publicData.claimIndex as number,
        publicData.claimType as ClaimType,
        publicData.arweaveURL as string,
        publicData.isPayable as boolean,
        publicData.animation as string,
        publicData.fallbackProvider as string || undefined,

        publicData.startDate ? new Date(publicData.startDate as string) : undefined,
        publicData.endDate ? new Date(publicData.endDate as string) : undefined
    );
  }
}
