import { ethers } from 'ethers';
import { Claim721 } from '@/classes/claimExtensionContract';

export function isEmptyMerkleRoot (merkleTree: string): boolean {
  return merkleTree === ethers.utils.formatBytes32String('');
}

export function isExclusiveClaim (claim: Claim721): boolean {
  return !isEmptyMerkleRoot(claim.merkleRoot);
}

export function isUnlimitedSupply (claim: Claim721): boolean {
  return claim.totalMax === 0;
}

export function abbreviate (str: string, keepLeft = 4, keepRight = 4): string {
  if (!str) return str;
  return (
    str.substring(0, keepLeft) +
    '...' +
    str.substring(str.length - keepRight)
  );
}

export function abbreviateAddress (address: string): string {
  return abbreviate(address, 6, 4);
}
