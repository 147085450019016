import { Ref, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { AuthState } from '@/store/modules/account.store';

type UseCollector = {
  walletAddress: Ref<string | undefined>;
  walletENSName: Ref<string | undefined>;
  network: Ref<string | undefined>;
  avatar: Ref<string | undefined>;
  jwt: Ref<string | undefined>;
  authState: Ref<AuthState>;
  isProviderAvailable: Ref<boolean>
}

export function useCollector (): UseCollector {
  const store = useStore();

  const walletAddress = ref<string | undefined>(store.state.Account.walletAddress);
  const walletENSName = ref<string | undefined>(store.state.Account.walletENSName);
  const network = ref<string | undefined>(store.state.Account.network);
  const avatar = ref<string | undefined>(store.state.Account.avatar);
  const jwt = ref<string | undefined>(store.state.Account.jwt);
  const authState = ref<AuthState>(store.state.Account.authState);
  const isProviderAvailable = ref<boolean>(store.state.Account.isProviderAvailable);

  watchEffect(() => {
    walletAddress.value = store.state.Account.walletAddress;
    walletENSName.value = store.state.Account.walletENSName;
    network.value = store.state.Account.network;
    avatar.value = store.state.Account.avatar;
    jwt.value = store.state.Account.jwt;
    authState.value = store.state.Account.authState;
    isProviderAvailable.value = store.state.Account.isProviderAvailable;
  });

  return {
    walletAddress,
    walletENSName,
    network,
    avatar,
    jwt,
    authState,
    isProviderAvailable
  };
}
