import apiHelpers from '@/api/apiHelpers';
import OAuthOneTimePassword from '@/api/models/oauth';

export interface IMerkleInfo {
  merkleProof: string[],
  value: number,
}
export interface ICollectorApiClient {
    getOAuthOneTimePassword(): Promise<OAuthOneTimePassword>;
    getMerkleInfos(campaignSlug: string, walletAddress: string): Promise<IMerkleInfo[]>;
}

class CollectorAPIClient implements ICollectorApiClient {
  async getOAuthOneTimePassword (): Promise<OAuthOneTimePassword> {
    return apiHelpers.post('/collectors/oauth/otp').then(OAuthOneTimePassword.fromJson);
  }

  async getMerkleInfos (campaignSlug: string, walletAddress: string): Promise<IMerkleInfo[]> {
    return apiHelpers.fetch(`/campaign/${campaignSlug}/merkleInfo?address=${walletAddress}&appId=2538262199`);
  }
}

export default new CollectorAPIClient();
