import { JWT_LOCAL_STORAGE_KEY, WALLET_LOCAL_STORAGE_KEY } from '@/common/constants';
import LocalStateManagement from '@/lib/local-state-management/index';

export default class AuthStateManagement {
    static saveJwt = (jwt: string) : void => {
      LocalStateManagement.saveState(JWT_LOCAL_STORAGE_KEY, jwt);
    }

    static getJwt = () : string | null => {
      return LocalStateManagement.getState(JWT_LOCAL_STORAGE_KEY);
    }

    static removeJwt = () : void => {
      LocalStateManagement.deleteState(JWT_LOCAL_STORAGE_KEY);
    }

    static saveWalletAddress = (address: string) : void => {
      LocalStateManagement.saveState(WALLET_LOCAL_STORAGE_KEY, address);
    }

    static getWalletAddress = () : string | null => {
      return LocalStateManagement.getState(WALLET_LOCAL_STORAGE_KEY);
    }

    static removeWalletAddress = () : void => {
      LocalStateManagement.deleteState(WALLET_LOCAL_STORAGE_KEY);
    }
}
