import { createStore } from 'vuex';
import modules from './modules';

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  actions: {
    reset ({ commit }: any) {
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/RESET_STATE`);
      });
    },
    resetAccountState ({ commit }: any) {
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/RESET_ACCOUNT_STATE`);
      });
    }
  },
  modules
});

export default store;
