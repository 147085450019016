<template>
  <div id="footer-container">
    <a id="footer" href="https://manifold.xyz" target="_blank">
      <div id="footer-logo" >
        <img src="../assets/images/manifold-logograph.svg" />
      </div>
      <span>Minted with Manifold</span>
    </a>
    <a id="footer-dev" href="https://www.souldrop.page/" target="_blank">
      <span>Created with Souldrop</span>
    </a>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss" scoped>
  #footer-container {
    width: 100%;
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-content: center;
    margin-bottom: 20px;
    padding: 20px 20px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 280px;
    }
  }

  #footer {
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    height: 48px;
    width: 100%;
    max-width: var(--maxWidth);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    #footer-logo {
      display: flex;
      align-items: center;
      padding-right: 8px;

      img {
        width: auto;
        height: 12px;
        object-fit: contain;
      }
    }

    span {
      width: max-content;
      font-size: 11px;
      color: var(--foregroundColor);
    }
  }

  #footer-dev {
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    height: 48px;
    width: 100%;
    max-width: var(--maxWidth);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    span {
      width: max-content;
      font-size: 11px;
      color: var(--foregroundColor);
    }
  }
</style>
