import { createApp } from 'vue';
import { VueQueryPlugin } from 'vue-query';
import './scss/styles.scss';
import store from '@/store/store';
import App from './App.vue';

const application = createApp(App);
application.use(store);

VueQueryPlugin.install(application, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: false
      }
    }
  }
});

application.mount('#app');
