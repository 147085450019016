export default (types: Array<string>, options = {}) : Record<string, string> => {
  const { namespace } = options as any;
  const container: any = {};

  types.forEach((type) => {
    container[type] = `${namespace || ''}${type}`;
  });

  return container;
};
