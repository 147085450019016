import { TransactionResponse } from '@ethersproject/abstract-provider';
import { ethers } from 'ethers';
import { markRaw } from 'vue';
import { ManifoldBridgeProvider } from '@manifoldxyz/manifold-provider-client';
import { ClaimType } from '@/api/models/claim';
import { EXTENSION_ABIS, EXTENSION_TRAITS, FREE_EXTENSION_MAINNET_721, FREE_EXTENSION_MAINNET_1155, NETWORK_ID } from '@/common/constants';

enum StorageProtocol { INVALID, NONE, ARWEAVE, IPFS }

export interface Claim1155 {
  total: number,
  totalMax: number,
  walletMax: number,
  startDate: number,
  endDate: number,
  storageProtocol: StorageProtocol,
  merkleRoot: string,
  location: string
}

export interface Claim1155Payable {
  total: number,
  totalMax: number,
  walletMax: number,
  startDate: number,
  endDate: number,
  storageProtocol: StorageProtocol,
  merkleRoot: string,
  location: string,
  tokenId: number,
  cost: string,
}

export interface Claim721Payable {
  total: number,
  totalMax: number,
  walletMax: number,
  startDate: number,
  endDate: number,
  storageProtocol: StorageProtocol,
  identical: boolean,
  merkleRoot: string,
  location: string
  cost: string,
}

export interface Claim721 {
  total: number,
  totalMax: number,
  walletMax: number,
  startDate: number,
  endDate: number,
  storageProtocol: StorageProtocol,
  identical: boolean,
  merkleRoot: string,
  location: string
}

interface ContractError {
    code: string,
    cancelled: boolean,
    replacement: {hash: string}
}

class ClaimExtensionContract {
    private extensionContractAddress : string
    private creatorContractAddress : string
    private claimIndex: number

    // Manifold bridge provider instance
    private manifoldBridgeProvider: ManifoldBridgeProvider | undefined

    constructor (extensionContractAddress: string, creatorContractAddress: string, claimIndex: number) {
      this.extensionContractAddress = extensionContractAddress;
      this.creatorContractAddress = creatorContractAddress;
      this.claimIndex = claimIndex;
    }

    protected _getContractInstance (spec: ClaimType, withSigner = false, bridge = false, unchecked = false): ethers.Contract {
      const abi: string[] = EXTENSION_ABIS[this.extensionContractAddress.toLowerCase()];
      if (bridge) return new ethers.Contract(this.extensionContractAddress, abi, this._getManifoldBridgeProvider());
      const contract = (window as any).ManifoldEthereumProvider.contractInstance(this.extensionContractAddress, abi, withSigner, unchecked);
      if (!contract) throw new Error('No contract instance available, please refresh this page to try again');
      return contract;
    }

    async getClaim (spec: ClaimType) : Promise<Claim721 | Claim1155 | Claim1155Payable | Claim721Payable> {
      let claimArray;
      try {
        claimArray = await this._callWeb3WithServerFallback('getClaim', [this.creatorContractAddress, this.claimIndex], spec);
      } catch (e) {
        console.log('error', e);
      }

      return this.processResult(claimArray, spec);
    }

    async checkMintIndices (mintIndices: number[], spec: ClaimType) : Promise<boolean[]> {
      return this._callWeb3WithServerFallback('checkMintIndices', [this.creatorContractAddress, this.claimIndex, mintIndices], spec);
    }

    async getTotalMints (walletAddress: string, spec: ClaimType) : Promise<number> {
      return this._callWeb3WithServerFallback('getTotalMints', [walletAddress, this.creatorContractAddress, this.claimIndex], spec);
    }

    async mint (mintIndex: number, merkleProofs: string[], spec: ClaimType, paymentAmount: ethers.BigNumber = ethers.BigNumber.from(0), walletAddress: string) : Promise<TransactionResponse | undefined> {
      let unchecked = false;
      try {
        if (localStorage.getItem('connectMethod') && localStorage.getItem('connectMethod') === 'walletConnect') {
          unchecked = true;
        }
        const gasLimit = await this.estimateGasMint(walletAddress, mintIndex, merkleProofs, spec, paymentAmount);
        const traits = EXTENSION_TRAITS[this.extensionContractAddress];
        // if using wallet connect with no provider, we will use wallet connect built in provider to make write calls,
        if (traits && traits.includes('delegateMint')) {
          return await this._getContractInstance(spec, true, false, unchecked).mint(this.creatorContractAddress, this.claimIndex, mintIndex, merkleProofs, walletAddress, {
            value: paymentAmount,
            gasLimit
          });
        } else {
          return await this._getContractInstance(spec, true, false, unchecked).mint(this.creatorContractAddress, this.claimIndex, mintIndex, merkleProofs, {
            value: paymentAmount,
            gasLimit
          });
        }
      } catch (e: any) {
        await this.errorHandling(e);
      }
    }

    async mintBatch (mintCount: number, mintIndices: number[], merkleProofs: string[][], spec: ClaimType, paymentAmount: ethers.BigNumber = ethers.BigNumber.from(0), walletAddress: string) : Promise<TransactionResponse | undefined> {
      let unchecked = false;
      try {
        const gasLimit = await this.estimateGasBatchMint(walletAddress, mintCount, mintIndices, merkleProofs, spec, paymentAmount);
        // if using wallet connect with no provider, we will use wallet connect built in provider to make write calls,
        if (localStorage.getItem('connectMethod') && localStorage.getItem('connectMethod') === 'walletConnect') {
          unchecked = true;
        }
        const traits = EXTENSION_TRAITS[this.extensionContractAddress];
        if (traits && traits.includes('delegateMint')) {
          return this._getContractInstance(spec, true, false, unchecked).mintBatch(this.creatorContractAddress, this.claimIndex, mintCount, mintIndices, merkleProofs, walletAddress, {
            value: paymentAmount,
            gasLimit
          });
        } else {
          return this._getContractInstance(spec, true, false, unchecked).mintBatch(this.creatorContractAddress, this.claimIndex, mintCount, mintIndices, merkleProofs, {
            value: paymentAmount,
            gasLimit
          });
        }
      } catch (e: any) {
        await this.errorHandling(e);
      }
    }

    async estimateGasMint (walletAddress: string, mintIndex: number, merkleProofs: string[], spec: ClaimType, paymentAmount: ethers.BigNumber = ethers.BigNumber.from(0)) : Promise<ethers.BigNumber> {
      const traits = EXTENSION_TRAITS[this.extensionContractAddress];
      let args;
      let functionSig = 'mint(address,uint256,uint32,bytes32[])';
      if (traits && traits.includes('delegateMint')) {
        args = [this.creatorContractAddress, this.claimIndex, mintIndex, merkleProofs, walletAddress,
          {
            from: walletAddress,
            value: paymentAmount
          }];
        functionSig = 'mint(address,uint256,uint32,bytes32[],address)';
      } else {
        args = [this.creatorContractAddress, this.claimIndex, mintIndex, merkleProofs,
          {
            from: walletAddress,
            value: paymentAmount
          }];
      }

      return this._estimateGas3WithServerFallback(functionSig, args, spec);
    }

    async estimateGasBatchMint (walletAddress: string, mintCount: number, mintIndices: number[], merkleProofs: string[][], spec: ClaimType, paymentAmount: ethers.BigNumber = ethers.BigNumber.from(0)) : Promise<ethers.BigNumber> {
      const traits = EXTENSION_TRAITS[this.extensionContractAddress];
      let args;
      let functionSig = 'mintBatch(address,uint256,uint16,uint32[],bytes32[][])';
      if (traits && traits.includes('delegateMint')) {
        args = [this.creatorContractAddress, this.claimIndex, mintCount, mintIndices, merkleProofs, walletAddress,
          {
            from: walletAddress,
            value: paymentAmount
          }];
        functionSig = 'mintBatch(address,uint256,uint16,uint32[],bytes32[][],address)';
      } else {
        args = [this.creatorContractAddress, this.claimIndex, mintCount, mintIndices, merkleProofs,
          {
            from: walletAddress,
            value: paymentAmount
          }];
      }
      return this._estimateGas3WithServerFallback(functionSig, args, spec);
    }

    async _estimateGas3WithServerFallback (functionSig: string, args: any[], spec: ClaimType) : Promise<ethers.BigNumber> {
      let gasEstimate;
      try {
        gasEstimate = await this._getContractInstance(spec, true).estimateGas[functionSig](...args);
      } catch (e) {
        // get etimate from manifold bridge instead
        gasEstimate = await this._getContractInstance(spec, true, true).estimateGas[functionSig](...args);
      }
      // Multiply gas estimate by 1.25 to account for inaccurate estimates from Metamask.
      gasEstimate = gasEstimate.mul(ethers.BigNumber.from('125').div(ethers.BigNumber.from('100')));
      return gasEstimate;
    }

    async _callWeb3WithServerFallback (functionName: string, args: any[], spec: ClaimType) : Promise<any> {
      const provider = (window as any).ManifoldEthereumProvider.provider();
      // @ts-ignore
      if (!provider) {
        // No available provider failure scenario, use the server endpoint
        return this._getContractInstance(spec, false, true)[functionName](...args);
      }
      try {
        // We have a web3timeout race because there are certain situations where
        // web3 requests will hang.  e.g. Safari websockets or Infura rate limiting
        // ref: https://developer.apple.com/forums/thread/679576
        // res: https://github.com/tilt-dev/tilt/issues/4746

        const web3timeout = new Promise(resolve => setTimeout(resolve, 2500));
        const web3result = new Promise((resolve) => {
          resolve(this._getContractInstance(spec, false)[functionName](...args));
        });

        let result : any = await Promise.race([web3timeout, web3result]);
        if (!result && result !== 0) {
          // Fallback provider failure scenario, use the server endpoint
          result = await this._getContractInstance(spec, false, true)[functionName](...args);
        }
        return result;
      } catch (e) {
        console.log(e);
        // try getting from server instead
        return this._getContractInstance(spec, false, true)[functionName](...args);
      }
    }

    async getEnsName (address: string) : Promise<any> {
      let provider = (window as any).ManifoldEthereumProvider.provider();
      if (!provider) {
        provider = this._getManifoldBridgeProvider();
      }
      return provider.lookupAddress(address);
    }

    processResult (claimArray: Array<any>, spec: ClaimType) : Claim721 | Claim1155 | Claim1155Payable | Claim721Payable {
      if (spec.toLowerCase() === 'erc721') {
        if (this.extensionContractAddress?.toLowerCase() === FREE_EXTENSION_MAINNET_721) {
          return {
            total: claimArray[0],
            totalMax: claimArray[1],
            walletMax: claimArray[2],
            startDate: claimArray[3],
            endDate: claimArray[4],
            storageProtocol: claimArray[5],
            merkleRoot: claimArray[7],
            location: claimArray[8]
          };
        } else {
          return {
            total: claimArray[0],
            totalMax: claimArray[1],
            walletMax: claimArray[2],
            startDate: claimArray[3],
            endDate: claimArray[4],
            storageProtocol: claimArray[5],
            merkleRoot: claimArray[7],
            location: claimArray[8],
            cost: ethers.BigNumber.from(claimArray[9]).toString()
          };
        }
      } else {
        // 1155
        if (this.extensionContractAddress?.toLowerCase() === FREE_EXTENSION_MAINNET_1155) {
          return {
            total: claimArray[0],
            totalMax: claimArray[1],
            walletMax: claimArray[2],
            startDate: claimArray[3],
            endDate: claimArray[4],
            storageProtocol: claimArray[5],
            merkleRoot: claimArray[6],
            location: claimArray[7]
          };
        } else {
          return {
            total: claimArray[0],
            totalMax: claimArray[1],
            walletMax: claimArray[2],
            startDate: claimArray[3],
            endDate: claimArray[4],
            storageProtocol: claimArray[5],
            merkleRoot: claimArray[6],
            location: claimArray[7],
            tokenId: claimArray[8],
            cost: ethers.BigNumber.from(claimArray[9]).toString()
          };
        }
      }
    }

    /**
   * Get the manifold bridge provider instance
   */
    private _getManifoldBridgeProvider (): ManifoldBridgeProvider {
      if (!this.manifoldBridgeProvider) {
        this.manifoldBridgeProvider = markRaw(new ManifoldBridgeProvider((window as any).ManifoldEthereumProvider.network() ?? NETWORK_ID));
      }
      return this.manifoldBridgeProvider;
    }

    async errorHandling (error: ContractError) : Promise<void> {
      if (error.code === 'TRANSACTION_REPLACED' && !error.cancelled && error.replacement) {
        const provider = (window as any).ManifoldEthereumProvider.provider();
        if (!provider) {
          throw new Error('No web3 provider detected, please refresh the page and try again');
        }
        const tx = await provider.getTransaction(error.replacement.hash);
        // Possible failed transaction
        if (!tx) {
          throw new Error('Failed Transaction');
        }
        await tx.wait(1);
      } else {
        throw error;
      }
    }
}

export default ClaimExtensionContract;
