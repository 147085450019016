
import { defineComponent, ref } from 'vue';
import '@/entry';
import { MClaimComplete } from '@/exports/';
import { ClaimConfig } from './api/models/claim';

export default defineComponent({
  name: 'ServeDev',

  setup: () => {
    const claimConfig = ref();
    const claimInstanceId = ref();
    const loading = ref(false);

    return {
      claimConfig,
      claimInstanceId,
      loading
    };
  },
  components: {
    MClaimComplete
  }

});
