import AuthStateManagement from '@/lib/local-state-management/auth';

export default class LocalStateManagement {
  static saveState (key: string, value: string) : void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      // Do nothing
    }
  }

  static getState (key: string) : string | null {
    try {
      return localStorage.getItem(key) || '';
    } catch (e) {
      return null;
    }
  }

  static deleteState (key: string) : void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      // Do nothing
    }
  }

  static saveObjectState = (object: Record<string, any>, valueArray: string[], key: string) : void => {
    try {
      valueArray.forEach(element => {
        LocalStateManagement.saveState(`${key}-cookie-${element}`, object[element]);
      });
    } catch (e) {
      // Do nothing
    }
  };

  static getObjectState = (valueArray: string[], key: string) : any => {
    const returnObject: any = {};
    try {
      valueArray.forEach(element => {
        if (LocalStateManagement.getState(`${key}-cookie-${element}`)) {
          returnObject[element] = LocalStateManagement.getState(`${key}-cookie-${element}`);
        }
      });
    } catch (e) {
      // Do nothing
    }
    return returnObject;
  };

  static deleteObjectState = (valueArray: string[], key: string) : void => {
    try {
      valueArray.forEach(element => {
        if (LocalStateManagement.getState(`${key}-cookie-${element}`)) {
          LocalStateManagement.deleteState(`${key}-cookie-${element}`);
        }
      });
    } catch (e) {
      // Do nothing
    }
  };
}

export {
  LocalStateManagement,
  AuthStateManagement
};
