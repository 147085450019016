import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { FIREBASE_GOERLI_CLAIM_COLLECTION_NAME, FIREBASE_MAINNET_CLAIM_COLLECTION_NAME } from './common/constants';

// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyCkvTXpnjmGm7hZQrnICgbgk6VnexCZ9xE',
  authDomain: 'claim-tip.firebaseapp.com',
  projectId: 'claim-tip',
  storageBucket: 'claim-tip.appspot.com',
  messagingSenderId: '100287022955',
  appId: '1:100287022955:web:6fd6a23b3ca2ec2d626e2e',
  measurementId: 'G-ZF2LM3B5SZ'
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// used for the firestore refs
const db = getFirestore(firebaseApp);

// here we can export reusable database references
export const goerliClaimCollection = collection(db, FIREBASE_GOERLI_CLAIM_COLLECTION_NAME);
export const mainnetClaimCollection = collection(db, FIREBASE_MAINNET_CLAIM_COLLECTION_NAME);

export const getFirebaseClaim = async (networkId: number, extensionContractAddress: string, creatorContractAddress: string, claimIndex: number) : Promise<any[]> => {
  if (networkId !== 5 && networkId !== 1) {
    throw new Error('Network not supported');
  }
  if (networkId === 5) {
    const q = query(goerliClaimCollection, where('extensionAddress', '==', extensionContractAddress), where('creatorContractAddress', '==', creatorContractAddress), where('claimIndex', '==', claimIndex));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data()) as any;
  }
  const q = query(mainnetClaimCollection, where('extensionAddress', '==', extensionContractAddress), where('creatorContractAddress', '==', creatorContractAddress), where('claimIndex', '==', claimIndex));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data()) as any;
};
