import { claim721Abi, claim721PayableAbi, claim1155Abi, claim1155PayableAbi } from '@/abis';
import claimABI721Airdrop from '@/abis/claim-abi-721-airdrop';
import claimABI1155Airdrop from '@/abis/claim-abi-1155-airdrop';
import isDev from '@/lib/isDev';

// events
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  5: '#4D99EB',
  42: '#886BF6',
  137: '#7B4ADD',
  6284: '#4D99EB'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  5: 'GOERLI',
  42: 'KOVAN',
  137: 'MATIC',
  6284: 'GOERLI'
};

export const WALLET_LOCAL_STORAGE_KEY = 'connectedAddress';
export const JWT_LOCAL_STORAGE_KEY = 'manifoldOAuth';
export const PREVIEW_PATH = '/c/manifold-campaign-preview';

export const FREE_EXTENSION_MAINNET_721 = '';
export const FREE_EXTENSION_MAINNET_1155 = '';

export const PAID_EXTENSION_GOERLI_721 = '';
export const PAID_EXTENSION_MAINNET_721 = '';
export const PAID_EXTENSION_GOERLI_1155 = '';
export const PAID_EXTENSION_MAINNET_1155 = '';

export const AIRDROP_EXTENSION_GOERLI_721 = '0x9fe49099f79de83852b6f42191432671c17d53e1';
export const AIRDROP_EXTENSION_MAINNET_721 = '0x0582a44b123fb333840b881e1521ceee4660f93b';
export const AIRDROP_EXTENSION_GOERLI_1155 = '0x1fa9eb8844db4e9c3d12974faa4f93923af23d16';
export const AIRDROP_EXTENSION_MAINNET_1155 = '0x5bcd4a62a8bca29f5c0a6f41fecf8a70b8f4f46a';

export const EXTENSION_TRAITS: { [_: string]: string[] } = {
  [FREE_EXTENSION_MAINNET_721]: [],
  [FREE_EXTENSION_MAINNET_1155]: [],
  [PAID_EXTENSION_GOERLI_721]: [],
  [PAID_EXTENSION_GOERLI_1155]: [],
  [PAID_EXTENSION_MAINNET_721]: [],
  [PAID_EXTENSION_MAINNET_1155]: [],

  [AIRDROP_EXTENSION_GOERLI_721]: ['delegateMint'],
  [AIRDROP_EXTENSION_GOERLI_1155]: ['delegateMint'],
  [AIRDROP_EXTENSION_MAINNET_721]: ['delegateMint'],
  [AIRDROP_EXTENSION_MAINNET_1155]: ['delegateMint']
};

export const EXTENSION_ABIS: { [_: string]: any[] } = {
  [FREE_EXTENSION_MAINNET_721]: claim721Abi,
  [FREE_EXTENSION_MAINNET_1155]: claim1155Abi,

  [PAID_EXTENSION_GOERLI_721]: claim721PayableAbi,
  [PAID_EXTENSION_MAINNET_721]: claim721PayableAbi,
  [PAID_EXTENSION_GOERLI_1155]: claim1155PayableAbi,
  [PAID_EXTENSION_MAINNET_1155]: claim1155PayableAbi,

  [AIRDROP_EXTENSION_GOERLI_721]: claimABI721Airdrop,
  [AIRDROP_EXTENSION_MAINNET_721]: claimABI721Airdrop,
  [AIRDROP_EXTENSION_GOERLI_1155]: claimABI1155Airdrop,
  [AIRDROP_EXTENSION_MAINNET_1155]: claimABI1155Airdrop
};

export const NETWORK_ID = isDev() ? 5 : 1;

export const FIREBASE_GOERLI_CLAIM_COLLECTION_NAME = 'goerli_claim_soulbound';
export const FIREBASE_MAINNET_CLAIM_COLLECTION_NAME = 'mainnet_claim_soulbound';

export const DEFAULT_DOMINANT_COLOR = 'grey';
export const DEFAULT_END_GRADIENT_COLOR = 'black';
