import identicon from 'identicon';
import { Commit, Dispatch } from 'vuex';
import { ETHEREUM_NETWORK_NAMES, NETWORK_ID } from '@/common/constants';
import LocalStateManagement, { AuthStateManagement } from '@/lib/local-state-management/index';
import typesCreator from '@/lib/vuex-mutation-types-creator';

export const types : Record<string, string> = typesCreator([
  'SET_WALLET_ADDRESS',
  'SET_WALLET_ENS_NAME',
  'SET_NETWORK',
  'SET_AVATAR',
  'SET_PROVIDER_AVAILABLE',
  'FETCH_ACCOUNT_FAILURE',
  'SET_POLL',
  'RESET_ACCOUNT_STATE',
  'SET_AUTH_STATE'
]);

export enum AuthState {
  UNKNOWN,
    LOGGED_IN,
  GUEST
}
export interface AccountInterface {
  walletAddress: string | undefined;
  walletENSName: string | undefined;
  network: string | undefined;
  avatar: string;
  isProviderAvailable: boolean;
  currentPoll: any | undefined;
  authState: AuthState;
  [index: string]: any; // allows for object['property'] syntax
}

const initialState = () : AccountInterface =>
  ({
    walletAddress: AuthStateManagement.getWalletAddress(),
    walletENSName: undefined,
    network: undefined,
    avatar: '',
    isProviderAvailable: false,
    jwt: AuthStateManagement.getJwt(),
    currentPoll: undefined,
    authState: AuthState.GUEST
  } as AccountInterface);

const state = initialState();

const getters = {

  authState: (state: AccountInterface) : AuthState => {
    return state.authState;
  },

  getJwt: (state: AccountInterface) : string | undefined => {
    return state.jwt;
  },

  isProviderAvailable: (state: AccountInterface): boolean => {
    return state.isProviderAvailable;
  },

  getWalletAddress: (state: AccountInterface): string | undefined => {
    return state.walletAddress;
  },

  getENSName: (state: AccountInterface) : string | undefined => {
    return state.walletENSName;
  },

  getAvatar: (state: AccountInterface) : string => {
    return state.avatar;
  },

  getNetwork: (state: AccountInterface) : string | undefined => {
    return state.network;
  },

  getCurrentPoll: (state: AccountInterface) : any | undefined => {
    return state.currentPoll;
  }
};

const actions = {

  async detectProviderChanged (
    { commit } : { commit: Commit }
  ) : Promise<void> {
    const provider = (window as any).ManifoldEthereumProvider.provider();
    commit(types.SET_PROVIDER_AVAILABLE, !!provider);
  },
  setNetwork ({ commit }: { commit: Commit }, network: string) : void {
    commit(types.SET_NETWORK, network);
  },
  async detectProvider (
    { commit, dispatch } : { commit: Commit, dispatch: Dispatch }
  ) : Promise<void> {
    dispatch('detectProviderChanged');
    const selectedAddress = (window as any).ManifoldEthereumProvider.selectedAddress();
    const selectedENSName = (window as any).ManifoldEthereumProvider.selectedENSName();

    // Check if there is an address and a valid session
    if (selectedAddress) {
      dispatch('detectChainChanged'); // sets network for us
      commit(types.SET_WALLET_ADDRESS, selectedAddress);
      commit(types.SET_WALLET_ENS_NAME, selectedENSName);
      AuthStateManagement.saveWalletAddress(selectedAddress);
      identicon.generate({
        id: selectedAddress, size: 32
      }, (err: any, buffer: any) => {
        if (err) {
          throw err;
        }
        commit(types.SET_AVATAR, buffer);
      });
    } else {
      // No wallet, disconnected
      dispatch('logout');
    }

    window.dispatchEvent(new Event('app-authorization-changed'));
  },

  detectChainChanged ({ commit } : { commit: Commit }) : void {
    // We forcing network Id to be 1 to support wallet connect, therefore we don't care about other chain, just mainnet
    const isCorrectChain = (window as any).ManifoldEthereumProvider.chainIsCorrect();
    // If browser doesn't have provider, assume it is the right network
    if (isCorrectChain) {
      commit(types.SET_NETWORK, ETHEREUM_NETWORK_NAMES[NETWORK_ID] ?? 'unknown');
    } else {
      commit(types.SET_NETWORK, 'unknown');
    }
  },

  async fetchAccount ({ commit }: { commit: Commit }) : Promise<void> {
    try {
      commit(types.SET_AUTH_STATE, AuthState.LOGGED_IN);
    } catch (error: any) {
      console.warn(error);
      commit(types.FETCH_ACCOUNT_FAILURE);
      throw error;
    }
  },

  logout ({ dispatch }: { dispatch: Dispatch }) : void {
    dispatch('deAuthenticate');
  },

  setAuthState ({ commit }: { commit: Commit }, state: AuthState) : void {
    commit(types.SET_AUTH_STATE, state);
  },

  deAuthenticate ({ dispatch }: { commit: Commit, dispatch: Dispatch }) : void {
    // remove legacy jwt
    if (LocalStateManagement.getState('manifoldJWT')) {
      LocalStateManagement.deleteState('manifoldJWT');
    }
    AuthStateManagement.removeJwt();
    dispatch('resetAccountState');
  },

  setCurrentPoll ({ commit }: { commit: Commit }, poll: () => void) : void {
    commit(types.SET_POLL, poll);
  },

  resetAccountState ({ commit }: { commit: Commit }) : void {
    commit(types.RESET_ACCOUNT_STATE);
  }
};

const mutations = {
  [types.SET_AUTH_STATE] (state: AccountInterface, authState: AuthState) : void {
    state.authState = authState;
  },
  [types.SET_WALLET_ADDRESS] (state: AccountInterface, address: string | undefined) : void {
    state.walletAddress = address;
  },
  [types.SET_WALLET_ENS_NAME] (state: AccountInterface, ensName: string | undefined) : void {
    state.walletENSName = ensName;
  },
  [types.SET_NETWORK] (state: AccountInterface, network: string | undefined) : void {
    state.network = network;
  },
  [types.SET_AVATAR] (state: AccountInterface, avatar: string) : void {
    state.avatar = avatar;
  },
  [types.SET_JWT] (state: AccountInterface, jwt: string | undefined) : void {
    state.jwt = jwt;
  },
  [types.FETCH_ACCOUNT_FAILURE] () : void {
    // TO DO
  },
  [types.SET_PROVIDER_AVAILABLE] (state: AccountInterface, isProviderAvailable: boolean) : void {
    state.isProviderAvailable = isProviderAvailable;
  },
  [types.SET_POLL] (state: AccountInterface, poll: any | undefined) : void {
    state.currentPoll = poll;
  },
  [types.RESET_ACCOUNT_STATE] (state: AccountInterface) : void {
    const newState = initialState();
    // our state variable is a const that cannot be directly reassigned,
    // so to reset it we reset each key:value individually through iteration
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
