<template>
  <div class="pulsating-circle">
    <img src="@/assets/images/circle-dot-regular.svg" />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.pulsating-circle {
  --circleWidth: 12px;

  width: var(--circleWidth);
  height: var(--circleWidth);
  position: relative;

  &::before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: black;
    animation: pulse-ring 2.5s linear infinite;
  }

  img {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
      opacity: 1;
    }

    25% {
      transform: scale(0.8);
      opacity: 0.8;
    }

    50%,
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
</style>
